import React, { FC } from "react";
import { Grid } from "antd";

type TimelineProps = {
  items: Array<String>;
};

export const Timeline: FC<TimelineProps> = ({ items }) => {
  const screens = Grid.useBreakpoint();

  return (
    <>
      <ul
        className={`${
          screens["md"]
            ? "font-poppins sm:flex"
            : "relative border-l border-gray-300"
        }`}
      >
        {items &&
          items.map((item: any, index: any) => {
            if (screens["md"]) {
              return (
                <>
                  {index != items.length - 1 ? (
                    <li className="relative mb-6 sm:mb-0" key={index}>
                      <div className="flex items-center">
                        <div className="flex z-10 justify-center items-center w-6 h-6 bg-black rounded-full flex-shrink-0" />
                        <div className="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-gray-700" />
                      </div>
                      <div className="mt-3 sm:pr-8">
                        <h3 className="text-base font-poppins font-medium text-black">
                          {item}
                        </h3>
                      </div>
                    </li>
                  ) : (
                    <li className="relative mb-6 sm:mb-0" key={index}>
                      <div className="flex items-center">
                        <div className="flex z-10 justify-center items-center w-6 h-6 bg-black rounded-full flex-shrink-0" />
                      </div>
                      <div className="mt-3 sm:pr-8">
                        <h3 className="text-base font-poppins font-medium text-black">
                          {item}
                        </h3>
                      </div>
                    </li>
                  )}
                </>
              );
            } else {
              return (
                <li className="mb-10 ml-6" key={index}>
                  <div className="absolute w-4 h-4 bg-black rounded-full mt-1.5 -left-2 border border-white" />
                  <h3 className="text-base font-poppins font-medium text-black">{item}</h3>
                </li>
              );
            }
          })}
      </ul>
    </>
  );
};
