import React, { FC } from "react";

type ArrowProps = {
  color: "white" | "black";
};

export const Arrow: FC<ArrowProps> = ({ color }) => {
  return (
    <>
      <span className="pl-2">
        <span
          className={`after:content-[''] after:block after:box-border after:absolute after:w-[6px] after:h-[6px] after:border-t-2 after:border-r-2 after:border-solid ${
            color == "white" ? "after:border-white" : "after:border-black"
          } after:right-0 after:rotate-45	after:-bottom-0.5 box-border relative block w-20 lg:w-16 h-[6px] shadow-[inset_0_0_0_2px] border-t-2 border-b-2 border-transparent ${
            color == "white" ? "text-white" : "text-black"
          }`}
        />
      </span>
    </>
  );
};
